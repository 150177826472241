.not-found-container {
  margin: 10px auto;
  max-width: 600px;
  text-align: center;
}

h1.not-found-title {
  margin: 30px 0;
  font-size: 4em;
  line-height: 1;
  letter-spacing: -1px;
}
