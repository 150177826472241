.work-item {
  clear: both;
  overflow: hidden;
  padding-bottom: 3em;
}

.work-bubble {
  float: left;
  padding-right: 20px;
  width: 100px;
  height: 100px;
}
