.profile-pic {
  float: right;
  width: 202px;
  border: #ccc 1px solid;
  margin-left: 20px;

  @include media-query($on-mobile) {
    float: none;
    margin-bottom: 20px;
    margin-left: 0px;
  }
}

.footer-col {
  font-size: 12px;

  h4 {
    margin-bottom: 0;
    margin-top: 0;
    line-height: 30px;
  }

  .social-links {
    display: flex;
    flex-wrap: wrap;

    .social-link {
      display: flex;
      width: 100%;
      align-items: flex-start;
      line-height: 16px;
      margin-top: 6.5px;
      margin-bottom: 10px;

      img {
        width: 16px;
        height: 16px;
      }

      span,
      a {
        margin-left: 6px;
        word-break: break-all;
      }
    }

    @include media-query($on-mobile) {
      font-size: 14px;
      line-height: 38px;
    }
  }
}

#wechat-widget {
  display: none;
  position: absolute;
  background: #fff;
  border: #ddd 1px solid;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);

  padding: 24px;
  text-align: center;

  p {
    font-size: 11px;
    line-height: 16px;
    font-family: Helvetica, sans-serif;
    width: 170px;
    margin: 0 auto;
    margin-bottom: 14px;
  }

  #qr-code {
    width: 160px;
    height: 160px;
    border: none;
  }
}
