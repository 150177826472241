/**
 * Site header
 */
.site-header {
  border-bottom: 1px solid $grey-color-light;
  min-height: 56px;

  padding-top: 10px;
  padding-bottom: 10px;

  @include media-query($on-mobile) {
    padding-top: 0;
    padding-bottom: 0;
  }

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  font-size: 26px;
  line-height: 56px;
  letter-spacing: -1px;
  float: left;

  img {
    height: 23px;
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

/**
 * Site nav
 */
.site-nav {
  z-index: 10;
  float: right;
  line-height: 56px;
  background-color: $background-color;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  @include media-query($on-mobile) {
    position: absolute;
    top: 12px;
    right: 24px;

    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      border-radius: 5px;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 4px;
      text-align: center;

      background: $background-color url($base-url + '/assets/img/menu.png') 6px 4px no-repeat;
      background-size: 26px 26px;
    }

    .menu {
      clear: both;
      display: none;
      background-color: $background-color;
      min-width: 10em;
      padding-bottom: 5px;
    }

    .page-link {
      font-size: 16px;
      display: block;
      padding: 6px 16px;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;

  @include media-query($on-mobile) {
    padding-top: 10px;
  }
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;

  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1,
.footer-col-2,
.footer-col-3 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width: calc(33% - (#{$spacing-unit} / 2));
}

.follow-links > .social-link {
  flex: 0 50%;
}

.powered-by {
  text-align: end;
  font-size: 13px !important;
  color: $grey-color;
}

@include media-query($on-mobile) {
  .footer-col {
    float: none;
    width: 100%;
  }

  .powered-by {
    text-align: start;
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}
